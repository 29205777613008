<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" title="详情" width="750px" @closed="handleClosed">
    <template v-if="form">
      <el-form ref="form" :model="form" label-width="66px" label-position="right" label-suffix=":">
        <div class="h s sb">
          <div style="width: 50%; margin-right: 30px;">
            <form-info-item label="单号">{{form.formCode}}</form-info-item>
            <form-info-item label="ERP单号" v-if="form.formErpCode && form.formErpCode !== form.formCode">{{form.formErpCode}}</form-info-item>
            <!-- <form-info-item label="供应商">{{form.distributorName}}</form-info-item> -->
            <form-info-item label="支付金额" inline>
              <b class="fs-large">{{$price(form.payMoney)}}</b>
            </form-info-item>
            <template v-if="form.status === '' || form.status === ''">
              <form-info-item label="返还金额" inline>
                <b class="fs-large">{{$price(form.rebackMoney)}}</b>
              </form-info-item>
              <form-info-item label="返还情况" inline>
                <b class="fs-large">{{form.toMoneyAccount==true?"返回账户余额":"线下已退余额"}}</b>
              </form-info-item>
            </template>
            <template v-if="form.payForm">
              <form-info-item label="手续费" inline>
                <b>{{$price(form.payForm.payFee)}}</b>
              </form-info-item>
              <template v-if="form.status !== 'unpay'">
                <form-info-item label="支付方式">{{paymentMethods[form.payForm.payType]}}</form-info-item>
                <form-info-item label="支付日期">{{new Date(form.payForm.payTime).format('yyyy/MM/dd')}}</form-info-item>
              </template>
              <form-info-item label="支付账户" v-if="form.payForm.payerAccount">{{form.payForm.payerAccount}}</form-info-item>
              <form-info-item label="收款账户" v-if="form.payForm.payeeAccount">{{form.payForm.payeeAccount}}</form-info-item>
              <form-info-item label="支付状态">
                <dot same :type="payStatus[form.payForm.payType][form.payForm.status].type">{{payStatus[form.payForm.payType][form.payForm.status].label}}</dot>
              </form-info-item>

            </template>
          </div>
          <div class="flex">
            <form-info-item label="创建人">{{form.createBy}}</form-info-item>
            <form-info-item label="创建时间">{{new Date(form.createAt).format()}}</form-info-item>
            <form-info-item label="当前状态">
              <dot same :type="status[form.status].type">{{status[form.status].label}}</dot>
            </form-info-item>
            <form-info-item label="返还时间">{{form.rebackTime?new Date(form.rebackTime).format():''}}</form-info-item>
            
          </div>
        </div>
        <form-info-item label="备注">{{form.info}}</form-info-item>
      </el-form>
      <div slot="footer" v-if="form && form.payForm && form.payForm.status !== 1">
        <el-button type="primary" @click="repay">立即支付</el-button>
      </div>
      <template v-if="payable && payForm">
        <el-divider content-position="left">支付费用</el-divider>
        <el-form :model="payForm" label-width="74px" label-position="right" label-suffix=":">
          <el-form-item label="支付方式">
            <el-radio-group v-model="payForm.payType">
              <el-radio-button :label="0" v-if="enablePayType !== 'offline'">在线支付</el-radio-button>
              <el-radio-button :label="1" v-if="enablePayType !== 'online'">线下支付</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <el-form ref="form" :model="payForm" label-width="74px" label-position="right" label-suffix=":" v-if="payForm.payType === 1">
          <el-form-item label="支付金额">
            <b class="fc-e">{{$price(form.payMoney - payForm.accountMoney)}}</b>
          </el-form-item>

          <template v-if="payForm.accountMoney < payForm._maxMoney">
            <el-form-item key="outpay" prop="payerAccount" label="支付账户">
              <div class="h s">
                <el-input v-model="payForm.payerAccount" maxlength="80" clearable class="flex" />
                <datagrid-picker :reference="true" reference-icon="fas fa-mouse-pointer" url="api/account" :popper-width="800" :show-index="false" @change="handlePayerAccountChange" style="margin-left: 6px; margin-top: -1px;">
                  <el-table-column label="账户类型" :formatter="r => {return formTypes[r.accountType];}" />
                  <el-table-column prop="accountCode" label="账号" />
                  <el-table-column prop="accountName" label="账户" />
                  <el-table-column prop="bankName" label="银行名称" />
                </datagrid-picker>
              </div>
            </el-form-item>
            <el-form-item key="outrec" prop="payeeAccount" label="收款账户">
              <div class="h s">
                <el-input v-model="payForm.payeeAccount" maxlength="80" clearable class="flex" />
                <datagrid-picker :reference="true" reference-icon="fas fa-mouse-pointer" url="api/account" :params="{entId:form.entId,sort: 'createAt,desc'}" :popper-width="800" :show-index="false" @change="handlePayeeAccountChange" style="margin-left: 6px; margin-top: -1px;">
                  <el-table-column label="账户类型" :formatter="r => {return formTypes[r.accountType];}" />
                  <el-table-column prop="accountCode" label="账号" />
                  <el-table-column prop="accountName" label="账户" />
                  <el-table-column prop="bankName" label="银行名称" />
                </datagrid-picker>
              </div>
            </el-form-item>
          </template>
          <el-form-item label="备注">
            <el-input type="textarea" v-model="payForm.info" :maxlength="100" :rows="2" resize="none" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" :loading="saving" @click="doPay">提交</el-button>
          </el-form-item>
        </el-form>
      </template>
      <online-pay ref="online" :money="form.payMoney" :supplier="form.entId" :order-form-id="form.id" :use-remainable="false" type="deposit" @paied="handleOnlinePaied" v-show="payable && payForm && payForm.payType === 0" />
    </template>
  </el-dialog>
</template>

<script>
import { pay } from "@/api/cost";
import onlinePay from "@/views/assembly/onlinePay";
import request from "@/utils/request";

export default {
  components: { onlinePay },
  data() {
    return {
      dialog: false,
      form: null,
      saving: false,
      payable: false,
      payForm: null,
      paymentMethods: ["在线支付", "线下支付"],
      formTypes: ["银行卡", "支付宝", "微信"],
      enablePayType: "all",
      payStatus: [
        [
          {
            type: "info",
            label: "待支付",
          },
          {
            type: "success",
            label: "支付成功",
          },
          {
            type: "danger",
            label: "支付失败",
          },
        ],
        [
          {
            type: "info",
            label: "待审批",
          },
          {
            type: "success",
            label: "已通过",
          },
          {
            type: "danger",
            label: "未通过",
          },
        ],
      ],
      status: {
        unpay: {
          type: "warning",
          label: "侍支付",
        },
        pay: {
          type: "primary",
          label: "支付中",
        },
        paySuccess: {
          type: "success",
          label: "支付成功",
        },
        payFailed: {
          type: "error",
          label: "支付失败",
        },
        reback: {
          type: "info",
          label: "已退还",
        },
        storIn: {
          type: "info",
          label: "已转入可用余额",
        },
      },
    };
  },
  methods: {
    handleClosed() {
      //this.form = null;
      //this.payable = false;
      this.payForm = null;
    },
    getEnablePayType() {
      if (this.form.entId) {
        request({
          url: "api/distributor/otherPayType/" + this.form.entId,
          method: "get",
        }).then((res) => {
          this.enablePayType = res;
          if (res === "offline") {
            this.payForm.payType = 1;
          } else if (res === "online") {
            this.payForm.payType = 0;
          }
        });
      }
    },
    repay() {
      if (this.form && this.form.payForm) {
        this.$refs.online && this.$refs.online.pay(this.form.payForm);
      }
    },
    doPay() {
      let form = JSON.parse(JSON.stringify(this.payForm));
      form.payMoney = form._maxMoney - form.accountMoney;
      if (form.payMoney <= 0) {
        form.payMoney = 0;
        form.payType = 1;
        form.payerAccount = "";
        form.payeeAccount = "";
      }
      if (form.payType === 0) {
        this.$alert("暂不支持在线支付功能。");
      } else {
        this.saving = true;
        pay(form)
          .then((res) => {
            this.$notify({
              title: "支付成功",
              type: "success",
              duration: 2500,
            });
            this.dialog = false;
            this.$parent.init();
          })
          .finally((_) => {
            this.saving = false;
          });
      }
    },
    handleOnlinePaied() {
      this.dialog = false;
      this.$parent.init();
    },
    resetForm(form, payable) {
      if (payable && (form.status === "unpay" || form.status === "pay" || form.status === 'payFailed')) {
        this.form = form;
        this.payable = true;
        this.payForm = {
          _maxMoney: form.payMoney,
          entId: form.entId,
          payMoney: form.payMoney,
          accountMoney: 0,
          info: "",
          payType: 0,
          payerAccount: "",
          payeeAccount: "",
          relationFormId: form.id,
          relationFormType: 5, //5表示保证金单据
        };
        this.getEnablePayType();
      } else {
        this.payable = false;
        this.payForm = null;
        this.form = form;
      }
      this.dialog = true;
    },
    handlePayerAccountChange(newVal, oldVal, row) {
      if (row.accountType === 0) {
        this.payForm.payerAccount = `${row.bankName} (${row.accountName}, ${row.accountCode})`;
      } else {
        this.payForm.payerAccount = `${this.formTypes[row.accountType]} (${
          row.accountCode
        })`;
      }
    },
    handlePayeeAccountChange(newVal, oldVal, row) {
      if (row.accountType === 0) {
        this.payForm.payeeAccount = `${row.bankName} (${row.accountName}, ${row.accountCode})`;
      } else {
        this.payForm.payeeAccount = `${this.formTypes[row.accountType]} (${
          row.accountCode
        })`;
      }
    },
  },
};
</script>