<template>
  <div class="app-container">
    <div class="head-container">
      <quick-select v-model="query.entId" filterable auto-select-first-option clearable url="api/purchase/seller" placeholder="选择供应商" value-field="belongTo" display-field="belongToName" @change="toQuery" class="filter-item" style="width: 180px;" />
      <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <el-button type="primary" class="filter-item" size="mini" icon="el-icon-plus" @click="add">报名</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>
    <el-table v-loading="loading" row-key="id" :data="data" size="small" style="width: 100%;">
      <el-table-column prop="formCode" label="单号" width="130" fixed />
      <!-- <el-table-column prop="distributorName" label="供应商" min-width="160" show-overflow-tooltip /> -->
      <el-table-column prop="subjectName" label="活动名称" min-width="160" show-overflow-tooltip />
      <el-table-column label="活动说明" show-overflow-tooltip min-width="240">
        <template slot-scope="scope">{{scope.row.subject.info}}</template>
      </el-table-column>
      <el-table-column prop="payMoney" label="支付金额" align="right" width="120" :formatter="$price" />
      <el-table-column prop="rebackMoney" label="返还金额" align="right" width="120" :formatter="$price" />
      <!-- <el-table-column prop="toMoneyAccount" label="返还情况" min-width="160">
        <template slot-scope="scope">{{scope.row.toMoneyAccount==true?"返回账户余额":""}}</template>
      </el-table-column>
      <el-table-column label="返还时间" width="140" :formatter="r => {return r.rebackTime?new Date(r.rebackTime).format('yyyy/MM/dd hh:mm:ss'):''}" /> -->
      <el-table-column label="创建时间" width="140" :formatter="r => {return new Date(r.createAt).format('yyyy/MM/dd hh:mm:ss');}" />
      <el-table-column prop="info" label="备注" show-overflow-tooltip min-width="240" />
      <el-table-column label="状态" width="80" align="center" fixed="right">
        <template slot-scope="scope">
          <el-tag :type="status[scope.row.status].type">{{status[scope.row.status].label}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column width="150px" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <el-button size="mini" type="text" @click="edit(scope.row)" v-if="scope.row.status=='unpay' && scope.row.subject.endDate>$now.get()">编辑</el-button>
          <el-button size="mini" type="text" @click="detail(scope.row, true)" v-if="scope.row.status === 'unpay' && scope.row.subject.endDate>$now.get() || scope.row.status === 'pay' || scope.row.status === 'payFailed'">支付</el-button>
          <el-button size="mini" type="text" @click="detail(scope.row)">查看</el-button>

          <el-popover :ref="scope.row.id" placement="top" width="180">
            <p>确定删除本保证金单吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="doDelete(scope.row.id)">确定</el-button>
            </div>
            <el-button slot="reference" class="danger" type="text" size="mini" v-if="scope.row.status=='unpay'">删除</el-button>
          </el-popover>
        </div>
      </el-table-column>
    </el-table>
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
    <e-form ref="form" />
    <a-form ref="aform" :is-add="isAdd" />
  </div>
</template>

<script>
import initData from "@/mixins/initData";
import eForm from "./form";
import aForm from "./aform";
import { del } from "@/api/deposit";
export default {
  components: { eForm, aForm },
  mixins: [initData],
  data() {
    return {
      // unpay,//未支付
      // pay,//支付中
      // paySuccess,//支付成功 出现返还按钮 实现返还操作
      // payFailed,//支付失败
      // reback,//已退还
      // storIn//已转入可用余额
      delLoading: false,
      status: {
        unpay: {
          type: "warning",
          label: "侍支付",
        },
        pay: {
          type: "primary",
          label: "支付中",
        },
        paySuccess: {
          type: "success",
          label: "支付成功",
        },
        payFailed: {
          type: "error",
          label: "支付失败",
        },
        reback: {
          type: "info",
          label: "已退还",
        },
        storIn: {
          type: "info",
          label: "已转入可用余额",
        },
      },
      query: {
        entId: null,
      },
    };
  },
  methods: {
    beforeInit() {
      this.url = "api/deposit/distributor";
      let query = JSON.parse(JSON.stringify(this.query));
      query.sort = "createAt,desc";
      this.params = query;
      return true;
    },
    add() {
      this.isAdd = true;
      const _this = this.$refs.aform;
      _this.restForm();
    },
    edit(row) {
      this.isAdd = false;
      const _this = this.$refs.aform;
      _this.restForm(JSON.parse(JSON.stringify(row)));
    },
    doDelete(id) {
      this.delLoading = true;
      del(id)
        .then((res) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除保证金成功",
            type: "success",
            duration: 2500,
          });
        })
        .catch((err) => {
          this.delLoading = false;
        });
    },
    detail(row, payable = false) {
      this.$refs.form && this.$refs.form.resetForm(row, payable);
    },
  },
  mounted() {
    this.init();
  },
};
</script>